import * as React from 'react';
import {
  ImmutableQuery,
  InitialLoader,
  SearchkitManager,
  SearchkitProvider,
  TermQuery
} from 'searchkit/es';
import { ProductHeader, Space, ProductsContainer } from '../../components';
import { HitsPlaceholder } from './components';
import {
  ButtonColorFilter,
  ButtonStorageFilter,
  ClearFilter
} from '../../searchkit/filters';
import { ProductHits } from '../../searchkit/productHits';
import { SelectedFilters } from '../../searchkit/selectedFilters';
import { useGetFirstProductFromSearchKit } from './hooks';
import { ProductHeaderPlaceHolder } from '../../components/productHeader/productHeaderPlaceholder';
import styled, { ThemeProvider } from 'styled-components';
import { useGetContainerWidth } from '../../hooks';
import { ClearFilterButton as ClearFilterButtonComponent } from '../../searchkit/filters/clearFilter/clearFilterButton';
import { media } from '../../helpers';
import { NoHits } from '../../searchkit/noHits';

const ClearFilterButton = styled(ClearFilterButtonComponent)`
  align-self: flex-end;

  ${media.sm(`
    margin: 0 0 10px 5px;
  `)}
`;

interface IProps {
  sk: SearchkitManager;
  containerEl: HTMLElement;
}

interface IContainerProps {
  loading: boolean;
  className?: string;
  children: React.ReactNode;
}

const FilterContainer = styled(({ className, children }: IContainerProps) => (
  <div className={className}>{children}</div>
))`
  display: ${({ loading }: IContainerProps) => (loading ? 'none' : 'inherit')};
`;

export const InArticle = ({ containerEl, sk }: IProps) => {
  // This hook will fetch the first results from the searchKit resultSet
  const { name, image } = useGetFirstProductFromSearchKit(sk);
  const width = useGetContainerWidth(containerEl);

  return (
    <ThemeProvider
      theme={{
        containerWidth: width
      }}
    >
      <SearchkitProvider searchkit={sk}>
        <React.Fragment>
          {!name && <ProductHeaderPlaceHolder />}
          <ProductHeader
            imageSrc={image}
            title={name}
            filtersComponent={
              <FilterContainer loading={name === null}>
                <ButtonColorFilter />
                <Space />
                <ButtonStorageFilter />
                <ClearFilter Button={ClearFilterButton} />
              </FilterContainer>
            }
            selectedFiltersComponent={<SelectedFilters />}
          />
          <ProductsContainer>
            <InitialLoader component={HitsPlaceholder} />
            <ProductHits />
            <NoHits />
          </ProductsContainer>
        </React.Fragment>
      </SearchkitProvider>
    </ThemeProvider>
  );
};

export const createInArticleWidget = (
  containerEl: HTMLElement,
  slug: string
) => {
  const sk = new SearchkitManager(
    process.env.REACT_APP_ELASTIC_SEARCH_URL || '',
    {
      useHistory: false,
      searchUrlPath: 'search'
    }
  );
  // Add a new query based on the productSlug
  sk.addDefaultQuery((query: ImmutableQuery) =>
    query
      .addQuery(TermQuery('productSlug', slug))
      .setSort([{ price: { order: 'asc' } }])
  );
  return <InArticle containerEl={containerEl} sk={sk} />;
};
